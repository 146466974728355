import React, { useMemo, useState, useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { map, reduce } from 'lodash';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Button from '../Forms/Button/Button';
import albero from '../../assets/icons/albero_white.svg';
import ImageBlock from '../ImageBlock/ImageBlock';
import './style.css';
import BlockLayout from '../../layaouts/BlockLayout/BlockLayout';
import AreeProgetto from '../AreeProgetto/AreeProgetto';
import MapSvg from '../../assets/icons/map.svg';
import Mask2 from '../../assets/mask2.png';
import Mappa from '../Mappa/Mappa';
import arrR from '../../assets/icons/arrR.svg';
import Carusel from '../Forms/Carusel/Carusel';
import MetaTags from '../Header/MetaTags';

function Fondazione({ data, projects }) {
  const intl = useIntl();
  const breakpoints = useBreakpoint();

  const {
    foundationHero, descriptionParagraph, companyBlock, whyAcutis,
  } = data[intl.locale].acf;
  const areeTitle = data[intl.locale].acf.projectAreas.blockTitleProjectAreas;
  const areeIcon = data[intl.locale].acf.projectAreas.blockIcon;
  const { yoast_head_json } = data[intl.locale];
  const acfAree = [
    {
      url: data[intl.locale].acf.projectAreas.firstCardBackgroundImage,
      title: data[intl.locale].acf.projectAreas.firstCardText,
      color: '#003F6E',
      icon: data[intl.locale].acf.projectAreas.firstCardIcon,
    },
    {
      url: data[intl.locale].acf.projectAreas.secondCardBackgroundImage,
      title: data[intl.locale].acf.projectAreas.secondCardText,
      color: '#A6322E',
      icon: data[intl.locale].acf.projectAreas.secondCardIcon,
    },
    {
      url: data[intl.locale].acf.projectAreas.thirdCardBackgroundImage,
      title: data[intl.locale].acf.projectAreas.thirdCardText,
      color: '#D75F2B',
      icon: data[intl.locale].acf.projectAreas.thirdCardIcon,
    },
  ];

  const features = useMemo(
    () => map(projects[intl.locale], (proj) => {
      const [icons, categories] = reduce(
        proj.categories,
        (acc, { name, acf }) => {
          acc[0].push(acf['Category Icon'].categoryIcon);
          acc[1].push(name);
          return acc;
        },
        [[], []],
      );

      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [
            parseFloat(proj.acf.map.longitude),
            parseFloat(proj.acf.map.latitude),
          ],
        },
        properties: {
          title: proj.title.rendered,
          iconSize: [50, 50],
          // subTitle: 'Loren asd asd',
          btnLabel: intl.formatMessage({ id: 'btn_leggi_tutto' }),
          footerIcon: icons,
          footerCard: categories.join(' , '),
          description: proj.acf.projectPreview.projectExcerpt,
          url: proj.acf.projectPreview.projectImage,
          icon: MapSvg,
          color: '#003F6E',
          slug: `/${proj.slug}`,
        },
      };
    }),
    [projects, intl],
  );

  const [collapse, setCollapse] = useState(true);
  const btnLabel = collapse
    ? intl.formatMessage({ id: 'btn_vedi_di_piu' })
    : intl.formatMessage({ id: 'btn_vedi_di_meno' });

  useEffect(() => {
    const mylist = document.getElementById('fondazione');
    const divs = mylist.children;
    const listitems = [];
    for (let i = 0; i < divs.length; i++) {
      listitems.push(divs.item(i));
    }

    listitems.sort((a, b) => {
      const compA = a.getAttribute('id');
      const compB = b.getAttribute('id');
      return compA < compB ? -1 : compA > compB ? 1 : 0;
    });
    for (let i = 0; i < listitems.length; i++) {
      mylist.appendChild(listitems[i]);
    }
  }, []);

  return (
    <>
      <MetaTags data={yoast_head_json} />
      <div id="fondazione" style={{ backgroundColor: '#f8f9fa' }}>
        {(foundationHero.positionFoundationHero && foundationHero.title) && (
        <div id={foundationHero.positionFoundationHero}>
          <ImageBlock
            type="3"
            title={foundationHero.title}
            img={foundationHero.imageBackgroundFoundationHero}
          />
        </div>
        )}

        {(descriptionParagraph.paragraphTitle && descriptionParagraph.contentVisibile) && (
        <div id={descriptionParagraph.positionParagraph}>
          <div className="collapsableTextBox">
            <h1 className="titleBox">{descriptionParagraph.paragraphTitle}</h1>
            {descriptionParagraph.contentVisibile && (
            <div className="collapsable">
              <div className="collapsableText">
                <span
                  dangerouslySetInnerHTML={{
                    __html: collapse
                      ? descriptionParagraph.contentVisibile
                      : descriptionParagraph.contentVisibile.concat(
                        descriptionParagraph.contentHidden,
                      ),
                  }}
                />
              </div>

              {descriptionParagraph.contentHidden && (
              <span style={{
                display: 'flex',
                flexDirection: 'row',
              }}
              >
                <Button
                  handleClick={() => {
                    setCollapse(!collapse);
                  }}
                  id="collapseBtn"
                  label={btnLabel}
                  style={{
                    borderRadius: 0,
                    textTransform: 'uppercase',
                    color: 'black',
                    fontStyle: 'normal',
                    fontWeight: 300,
                    fontSize: '14px',
                    lineHeight: '21px',
                  }}
                >
                  {collapse ? (
                    <img
                      alt="accordionIMG"
                      src={arrR}
                      style={{
                        marginLeft: 6,
                        width: '16px',
                        height: '16px',
                      }}
                    />
                  ) : (
                    <img
                      alt="accordionIMG"
                      src={arrR}
                      style={{
                        marginLeft: 6,
                        width: '16px',
                        height: '16px',
                        transform: 'rotate(-180deg)',
                      }}
                    />
                  )}
                </Button>
              </span>
              )}
            </div>
            )}
          </div>
        </div>
        )}

        {(companyBlock.companyTitle && companyBlock.companyBackgroundImage) && (
        <div id={companyBlock.positionCompany}>
          <ImageBlock
            type="2"
            title={companyBlock.companyTitle}
            img={companyBlock.companyBackgroundImage}
            label={companyBlock.companyDescription}
            btnlabel={companyBlock.companyButtonLabel}
          />
        </div>
        )}

        {(whyAcutis.blockTitleWhyAcutis && whyAcutis.firstIcon && whyAcutis.firstSubtitle && whyAcutis.firstParagraph) && (
        <div
          id={whyAcutis.positionWhyAcutis}
          style={{
            width: '100%',
            backgroundColor: '#f8f9fa',
            backgroundImage: `url(${albero})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: !breakpoints.md ? '80%' : '250%',
            backgroundPosition: !breakpoints.md ? '310% -100%' : '0% 120%',
            paddingBottom: 50,
          }}
        >
          <div className="middleContainer">
            <img
              alt="fondazioneIMG"
              src={Mask2}
              style={{
                position: 'absolute',
                zIndex: 3,
              }}
            />
            <h2 className="subtitle">{whyAcutis.blockTitleWhyAcutis}</h2>
            <BlockLayout
              isTitleH3
              icon={whyAcutis.firstIcon}
              title={whyAcutis.firstSubtitle}
            >
              <span
                className="paragraph"
                dangerouslySetInnerHTML={{ __html: whyAcutis.firstParagraph }}
              />
            </BlockLayout>
            {(whyAcutis.secondParagraph) && (
            <BlockLayout
              isTitleH3
              icon={whyAcutis.secondIcon}
              title={whyAcutis.secondSubtitle}
            >
              <span
                className="paragraph"
                dangerouslySetInnerHTML={{ __html: whyAcutis.secondParagraph }}
              />
            </BlockLayout>
            )}
            {(whyAcutis.thirdParagraph) && (
            <BlockLayout
              isTitleH3
              icon={whyAcutis.thirdIcon}
              title={whyAcutis.thirdSubtitle}
            >
              <span
                className="paragraph"
                dangerouslySetInnerHTML={{ __html: whyAcutis.thirdParagraph }}
              />
            </BlockLayout>
            )}
          </div>
        </div>
        )}

        {(acfAree) && (
        <div id={data[intl.locale].acf.projectAreas.positionProjectAreas}>
          <BlockLayout icon={areeIcon} title={areeTitle} bgColor="white">
            {breakpoints.md ? (
              <Carusel
                component="AreeProgetto"
                cards={acfAree.filter((area) => area.title !== '')}
                width={256}
                height={359}
                btnlabel={data[intl.locale].acf.projectAreas.buttonLabel}
              />
            ) : (
              <AreeProgetto
                aree={acfAree.filter((area) => area.title !== '')}
                className="areeWeb"
                btnlabel={data[intl.locale].acf.projectAreas.buttonLabel}
                goto={data[intl.locale].acf.projectAreas.buttonUrl}
              />
            )}
            <br />
            <br />
          </BlockLayout>
        </div>
        )}

        {features && (
        <div id={data[intl.locale].acf.ourProjects.positionProjects}>
          <BlockLayout
            icon={data[intl.locale].acf.ourProjects.iconProjects}
            title={data[intl.locale].acf.ourProjects.titleProjects}
            bgColor="#f8f9fa"
          >
            <Mappa features={features} />
          </BlockLayout>
        </div>
        )}
      </div>
    </>
  );
}

export default Fondazione;
