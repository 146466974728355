import React, { useCallback, useMemo, useState } from 'react'
import { map, take } from 'lodash'
import { Link, navigate } from 'gatsby-plugin-intl'
import Button from '../Forms/Button/Button'
import './style.css'

export default function AreeProgetto({ aree = [], btnlabel, goto }) {
  const [numAreeToDisplay, setNumAreeToDisplay] = useState(3)

  const showMoreAreas = useCallback(() => {
    setNumAreeToDisplay(numAreeToDisplay + 3)
  }, [numAreeToDisplay])

  const areas = useMemo(
    () =>
      map(aree, area => (
        <div
          className="area"
          key={area.title}
          style={{ backgroundImage: `url(${area.url})` }}
          onClick={() => navigate(`/cosa-facciamo?filter=${area.title}`)}
        >
          <div
            className="area_content_icon"
            style={{ backgroundColor: area.color }}
          >
            <img alt="areaProgettoIMG" className="area_icon" src={area.icon} />
          </div>
          <div style={{ width: '100%', position: 'relative' }}>
            <div
              className="area_content_title"
              style={{
                backgroundColor: area.color,
                opacity: 0.5,
                height: 78,
              }}
            ></div>
            <div className="area_text">{area.title}</div>
          </div>
        </div>
      )),
    [aree]
  )

  const areasToDisplay = useMemo(() => take(areas, numAreeToDisplay), [
    areas,
    numAreeToDisplay,
  ])

  return (
    <div className="mainAreeContainer">
      <div className="aree_progetto_container">{areasToDisplay}</div>
      <Link to={goto} style={{ textDecoration: 'none' }}>
        <Button
          data-action="cta scopri"
          data-name-cta={btnlabel}
          label={btnlabel}
          style={{
            width: 160,
            backgroundColor: '#D75F2B',
            color: 'white',
            marginTop: 40,
          }}
        />
      </Link>
    </div>
  )
}
