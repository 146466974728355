import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import Fondazione from '../components/Fondazione/Fondazione';
import MainLayout from '../layaouts/MainLayout/MainLayout';

function FondazionePage({ pageContext }) {
  const {
    data,
    projects,
  } = pageContext;

  return (
    <MainLayout>
      <Fondazione data={data} projects={projects} />
    </MainLayout>
  );
}

export default injectIntl(FondazionePage);
